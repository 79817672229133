import { useDispatch } from 'react-redux';
import { deleteJob, changeJobStatus } from '../../features/job/jobSlice'; 


export default function SingleJob(props) {
    const { job } = props;

    const dispatch = useDispatch();

    const categories = job.categories ? JSON.parse(job.categories) : [];

    const handleDelete = () => {
        if (window.confirm("Are you sure you want to delete this job?")) {
            dispatch(deleteJob(job.id));
        }
    };

    const handleEdit = () => {
        const newStatus = job.status === 'processing' ? 'stopped' : 'processing';
        dispatch(changeJobStatus({ jobId: job.id, newStatus }));
    };

    return (
        <tr>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {job.title}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{job.scrapping_link}</td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {
                    categories && (
                        categories.map((category) => ( 
                            
                            category.label + ','

                        ))
                    )
                }
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{job.current_page}</td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{job.interval_time}</td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{job.last_page}</td>
            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                <button onClick={handleEdit} className="text-indigo-600 hover:text-indigo-900 mr-10">{ job.status === "processing" ? 'Stop' : 'Resume' }</button>
                <button onClick={handleDelete} className="text-red-600 hover:text-red-900">Delete</button>
            </td>
        </tr>
    );
}
