import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { createJob, fetchJobs } from '../../features/job/jobSlice'; // Update this import path as needed

export default function NewJob(props) {
  const { open, setOpen, collections } = props;
  const dispatch = useDispatch();

  const createJobStatus = useSelector((state) => state.job.createJobStatus);

  const [formData, setFormData] = useState({
    title: '',
    scrapping_link: '',
    last_page: 10,
    categories: [],
    interval_time: '',
  });

  const handleChange = (e) => {
    const { name, options } = e.target;
    if (name === 'categories') {
      const selectedOptions = Array.from(options)
        .filter(option => option.selected)
        .map(option => collections.find(collection => collection.value.toString() === option.value));
      setFormData(prevState => ({ ...prevState, [name]: selectedOptions }));
    } else {
      setFormData(prevState => ({ ...prevState, [name]: e.target.value }));
    }
  };


  

  const handleSubmit = (e) => {
    e.preventDefault();
    const jobData = {
      ...formData,
      categories: JSON.stringify(formData.categories),
    };
    dispatch(createJob(jobData));
    dispatch(fetchJobs());
    setOpen(false);
  };


  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
                      <Dialog.Title as="h3" className="text-lg font-bold leading-6 text-gray-900">
                        Add New Job
                      </Dialog.Title>
                      <div className="py-10">

                        <div className="grid grid-cols-1 gap-y-6 gap-x-4">

                          <div className="sm:col-span-6">
                            <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                              Title
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="title"
                                id="title"
                                onChange={handleChange}
                                required
                                autoComplete="given-name"
                                className="block w-full rounded-md px-3 border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>

                          <div className="sm:col-span-6">
                            <label htmlFor="scrapping_link" className="block text-sm font-medium leading-6 text-gray-900">
                              Scrapping Link
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="scrapping_link"
                                id="scrapping_link"
                                autoComplete="given-name"
                                required
                                onChange={handleChange}
                                className="block w-full rounded-md px-3 border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>

                          <div className="sm:col-span-6">
                            <label htmlFor="last_page" className="block text-sm font-medium leading-6 text-gray-900">
                              Last Page*
                            </label>
                            <div className="mt-1">
                              <input
                                type="number"
                                name="last_page"
                                id="last_page"
                                onChange={handleChange}
                                required
                                autoComplete=""
                                className="block w-full rounded-md px-3 border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>

                          <div className="sm:col-span-6">
                            <label htmlFor="categories" className="block text-sm font-medium leading-6 text-gray-900">
                              Collections
                            </label>
                            <div className="mt-1">
                              <select
                                id="categories"
                                name="categories"
                                autoComplete="categories"
                                multiple
                                required
                                onChange={handleChange}
                                className="block w-full rounded-md px-3 border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              >
                                <option className="" disabled>Select</option>
                                {
                                  collections.map((collection, index) => (
                                    <option key={index} value={collection.value}>{collection.label}</option>
                                  ))
                                }
                              </select>
                            </div>
                          </div>

                          <div className="sm:col-span-6">
                            <label htmlFor="interval_time" className="block text-sm font-medium leading-6 text-gray-900">
                              Interval Time
                            </label>
                            <div className="mt-1">
                              <select
                                id="interval_time"
                                name="interval_time"
                                autoComplete="interval_time"
                                required
                                onChange={handleChange}
                                className="block w-full rounded-md px-3 border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              >
                                <option value="">Select Interval</option>
                                <option value="every-day">Every Day</option>
                                <option value="every-week">Every Week</option>
                                <option value="every-month">Every Month</option>
                              </select>
                            </div>
                          </div>

                        </div>


                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      disabled={createJobStatus === "loading"}
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"

                    >
                      {createJobStatus === "loading" ? 'Adding...' : 'Add New'}
                    </button>
                    <button
                      type="button"

                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
