import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../axios'; 

export const fetchCollections = createAsyncThunk(
    'shopify/fetchCollections',
    async () => {
      const response = await API.get('/shopify/collections');
      return response.data;
    }
);

const shopifySlice = createSlice({
  name: 'shopify',
  initialState: {
    data: [],
    status: 'idle', 
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCollections.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCollections.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchCollections.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default shopifySlice.reducer;
