import { combineReducers } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import jobReducer from '../features/job/jobSlice';
import shopifyCollectionsReducer from '../features/shopify/collections';

const rootReducer = combineReducers({
  auth: authReducer,
  job: jobReducer,
  shopify: shopifyCollectionsReducer
});

export default rootReducer;
