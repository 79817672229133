import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../axios';

export const fetchJobs = createAsyncThunk(
  'job/fetchJobs',
  async () => {
    const response = await API.get('/job');
    return response.data;
  }
);

// Async thunk for creating a new job
export const createJob = createAsyncThunk(
  'job/createJob',
  async (jobData, { rejectWithValue }) => {
    try {
      const response = await API.post('/job', jobData); 
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteJob = createAsyncThunk(
  'job/deleteJob',
  async (jobId, { rejectWithValue }) => {
    try {
      const response = await API.delete(`/job/${jobId}`);
      return jobId;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const changeJobStatus = createAsyncThunk(
  'job/changeJobStatus',
  async ({ jobId, newStatus }, { rejectWithValue }) => {
    try {
      const response = await API.post(`/job/change-status/${jobId}`, { status: newStatus });
      return { jobId }; // Return updated job data
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const jobSlice = createSlice({
  name: 'job',
  initialState: {
    isNewJobDialogOpen: false,
    data: [],
    status: 'idle', 
    error: null,
    createJobStatus: 'idle', // Additional state for job creation status
    createJobError: null, // Additional state for job creation error
  },
  reducers: {
    toggleNewJobDialog: (state, action) => {
      state.isNewJobDialogOpen = !state.isNewJobDialogOpen;
    },
  },
  extraReducers: (builder) => {
    builder
    
      // Existing reducers for fetching jobs
      .addCase(fetchJobs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchJobs.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchJobs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Reducers for creating a new job
      .addCase(createJob.pending, (state) => {
        state.createJobStatus = 'loading';
      })
      .addCase(createJob.fulfilled, (state, action) => {
        state.createJobStatus = 'succeeded';
        state.data.push(action.payload); // Add the new job to the job list
      })
      .addCase(createJob.rejected, (state, action) => {
        state.createJobStatus = 'failed';
        state.createJobError = action.payload;
      })
      .addCase(deleteJob.fulfilled, (state, action) => {
        state.data = state.data.filter(job => job.id !== action.payload);
      })
      .addCase(changeJobStatus.fulfilled, (state, action) => {
        const index = state.data.findIndex(job => job.id === action.payload.jobId);
        if (index !== -1) {
          state.data[index].status =  state.data[index].status === 'stopped' ? 'processing' : 'stopped';
        }
      });
      

      
  },
});

export const { toggleNewJobDialog } = jobSlice.actions;
export default jobSlice.reducer;
