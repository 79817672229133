import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setToken } from '../features/auth/authSlice';
import authService from '../features/auth/authService';
import { AuthLayout } from '../components/common/AuthLayout';
import { TextField } from '../components/common/Fields';
import { Button } from '../components/common/Button'


const LoginPage = () => {
  const [userData, setUserData] = useState({ email: '', password: '' });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const data = await authService.login(userData);
      localStorage.setItem('token', data.token);  // Save token to local storage
      dispatch(setToken(data.token));
      navigate('/'); // Navigate to homepage
    } catch (error) {
      console.error('Login failed:', error);
      // Handle login errors here
    }
  };

  return (
    <div>
        <AuthLayout>
        <div className="flex flex-col">
          <div>
            <h2 className="text-lg font-semibold text-gray-900">
              Sign in to your account
            </h2>
          </div>
        </div>
        <form onSubmit={handleLogin} className="mt-10 grid grid-cols-1 gap-y-8">
          <TextField
            label="Email address"
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            value={userData.email} 
            onChange={handleInputChange} 
            required
          />
          <TextField
            label="Password"
            id="password"
            name="password"
            type="password"
            autoComplete="current-password"
            value={userData.password} 
            onChange={handleInputChange} 
            required
          />
          <div>
            <Button
              type="submit"
              variant="solid"
              className="w-full bg-indigo-600"
            >
              <span>
                Sign in <span aria-hidden="true">&rarr;</span>
              </span>
            </Button>
          </div>
        </form>
      </AuthLayout>
    </div>
  );
};

export default LoginPage;
