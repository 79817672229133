import axios from 'axios';

const API_URL = 'https://api.luxurydressingroom.com/api/auth'; // replace with your API URL

const login = async (userData) => {
  const response = await axios.post(`${API_URL}/login`, userData);
  if (response.data.token) {
    localStorage.setItem('user', JSON.stringify(response.data));
  }
  return response.data;
};

const authService = {
  login,
  // other auth related services
};

export default authService;
